import React, { useState } from 'react';
import ProjectConfig from '../constants';
import updateUserSalary from '../api/users/updateUserSalary';

interface GrossSalaryInputComponentProps {
	token: string;
	onInform: (salary: number) => void;
	userId: string;
}

const GrossSalaryInputComponent: React.FC<GrossSalaryInputComponentProps> = ({
	token,
	onInform,
	userId
}) => {
	const [showInput, setShowInput] = useState<boolean>(false);
	const [salary, setSalary] = useState<number | ''>('');

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		setSalary(value ? Number(value) : '');
	};

	const handleSubmit = async () => {
		if (salary !== '') {
			console.log('salary of userId', userId, salary);
			try {
				await updateUserSalary(token, userId, salary);
				onInform(salary);
				setShowInput(false);
			} catch (error) {
				console.error('Error updating salary:', error);
			}
		}
	};

	return (
		<div>
			{!showInput ? (
				<button
					onClick={() => setShowInput(true)}
					style={{
						maxWidth: '150px',
						fontSize: '12px',
						padding: '2px 4px',
						backgroundColor: ProjectConfig.ARCA_COLORS.PINK_300,
						color: ProjectConfig.ARCA_COLORS.WHITE,
						border: 'none',
						borderRadius: '2px',
						cursor: 'pointer'
					}}>
					Informar dato salario
				</button>
			) : (
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<input
						type="number"
						min="0"
						placeholder="Ingrese salario"
						value={salary}
						onChange={handleInputChange}
						style={{
							width: '60%',
							fontSize: '12px',
							padding: '2px 4px',
							border: `1px solid ${ProjectConfig.ARCA_COLORS.LIGHT_GREY_01}`,
							borderRadius: '2px'
						}}
					/>
					<button
						onClick={handleSubmit}
						style={{
							width: '25px',
							marginLeft: '4px',
							fontSize: '12px',
							padding: '2px 4px',
							backgroundColor: ProjectConfig.ARCA_COLORS.PINK_300,
							color: ProjectConfig.ARCA_COLORS.WHITE,
							border: 'none',
							borderRadius: '2px',
							cursor: 'pointer'
						}}>
						✅
					</button>
					<button
						onClick={() => setShowInput(false)}
						style={{
							width: '25px',
							marginLeft: '4px',
							fontSize: '12px',
							padding: '2px 4px',
							backgroundColor: ProjectConfig.ARCA_COLORS.LIGHT_GREY_01,
							color: ProjectConfig.ARCA_COLORS.BLACK,
							border: 'none',
							borderRadius: '2px',
							cursor: 'pointer'
						}}>
						❌
					</button>
				</div>
			)}
		</div>
	);
};

export default GrossSalaryInputComponent;
