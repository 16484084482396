import { Box, Text } from '@chakra-ui/react';

const ConfidenceBoosterLiteComponent = ({}) => {
	return (
		<Box display="flex" flexDirection="column" alignItems="center" mt={4} mb={4}>
			<Text>
				Tus planes de pensiones están gestionados por Caser Pensiones, asesorados por Indexa y
				depositados en Cecabank, dentro del marco del contrato de gestión de carteras que tienes con
				Indexa.
			</Text>
		</Box>
	);
};

export default ConfidenceBoosterLiteComponent;
