import React, { useState, useContext, useEffect } from 'react';
import '../styles/LoginForm.css';
import login from '../api/login/login';
import {
	Box,
	Button,
	IconButton,
	Input,
	InputGroup,
	InputRightElement,
	useDisclosure
} from '@chakra-ui/react';
import { ArcaContext } from '../context/ArcaContext.context';
import { useNavigate } from 'react-router';
import _ from 'lodash';
import GenericModal from './ChakraUi/Modals/GenericModal';
import ButtonSpinner from './ChakraUi/Spinners/ButtonSpinner';
import { ViewOffIcon, ViewIcon } from '@chakra-ui/icons';
import ProjectConfig from '../constants';
import { I18nContext } from '../context/translations/TranslationsContext.context';
import { jwtDecode } from 'jwt-decode';
// import { getAnalytics, logEvent } from 'firebase/analytics';

// const analytics = getAnalytics();

const LoginForm = ({ token }: { token?: string }) => {
	const {
		state: { translate }
	} = useContext(I18nContext);

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [showPassword, setShowPassword] = useState(false);

	const { setToken, setCompanyId, setUserId, setUserAccountType, setLanguage } =
		useContext(ArcaContext);
	const { isOpen, onOpen, onClose } = useDisclosure();

	const navigate = useNavigate();

	useEffect(() => {
		if (!isOpen) {
			setIsLoading(false);
		}
	}, [isOpen]);

	useEffect(() => {
		if (token) {
			handleTokenLogin(token);
		}
	}, [token]);

	const handleTokenLogin = async (token: string) => {
		setIsLoading(true);
		try {
			handleLoginWithToken(token);
			navigate('/dashboard/home');
		} catch (error) {
			console.error('Error during token login:', error);
			onOpen();
		}
	};

	const handleLogin = async (event: React.FormEvent) => {
		event.preventDefault(); // Prevent the default form submission

		setIsLoading(true);

		try {
			const response = await login(email, password);

			const responseData = response.data;

			if (!response.success || !_.isNil(responseData.error)) {
				// logEvent(analytics, 'login_error');
				onOpen();
				// setIsLoading(false);
				return;
			}

			handleLoginSuccess(responseData);
			// logEvent(analytics, 'login_success');

			navigate('/dashboard/home');
		} catch (error) {
			console.error('Error al iniciar sesión:', error);
			onOpen();
		}
	};

	const handleLoginSuccess = (responseData: any) => {
		const { user, token } = responseData;
		if (user) {
			const { companyId, id: userId, accountType, language } = user;

			setCompanyId(companyId);
			localStorage.setItem('companyId', companyId);

			setUserId(userId);
			localStorage.setItem('userId', userId);

			setUserAccountType(accountType);
			localStorage.setItem('userAccountType', accountType);

			setLanguage(language);
			localStorage.setItem('language', language);
		}

		if (token) {
			localStorage.setItem('token', token);
			setToken(token);
		}
	};

	const handleLoginWithToken = async (token: string) => {
		if (token) {
			localStorage.setItem('token', token);
			setToken(token);

			const decodedToken = jwtDecode<{
				id: string;
				accountType: string;
				companyId: string;
			}>(token);
			if (decodedToken) {
				const { id, accountType, companyId } = decodedToken;
				const language = 'es';

				setCompanyId(companyId);
				localStorage.setItem('companyId', companyId);

				setUserId(id);
				localStorage.setItem('userId', id);

				setUserAccountType(accountType);
				localStorage.setItem('userAccountType', accountType);

				setLanguage(language);
				localStorage.setItem('language', language);
			}
		}
	};

	return (
		<div className="login-form">
			<div className="container">
				<img src="/imgs/LOGO_VERDE_2.svg" alt="Logo Arca" />
				<form onSubmit={handleLogin}>
					<InputGroup mt={2}>
						<Input
							name="email"
							type="email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							borderColor={
								email
									? ProjectConfig.ARCA_COLORS.GREEN_500
									: ProjectConfig.ARCA_COLORS.LIGHT_GREY_01
							}
							_focus={{ borderColor: ProjectConfig.ARCA_COLORS.GREEN_500 }}
							placeholder={translate('general.login.email')}
						/>
					</InputGroup>

					<InputGroup mt={-2}>
						<Input
							name="password"
							type={showPassword ? 'text' : 'password'}
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							borderColor={
								password
									? ProjectConfig.ARCA_COLORS.GREEN_500
									: ProjectConfig.ARCA_COLORS.LIGHT_GREY_01
							}
							_focus={{ borderColor: ProjectConfig.ARCA_COLORS.GREEN_500 }}
							placeholder={translate('general.login.password')}
						/>
						<InputRightElement width="3rem" top="10px">
							<IconButton
								aria-label={showPassword ? 'Ocultar contraseña' : 'Mostrar contraseña'}
								icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
								onClick={() => setShowPassword(!showPassword)}
								variant="ghost"
								color={ProjectConfig.ARCA_COLORS.GREEN_900}
								fontSize="18px"
								transition="all 0.3s ease-in-out"
								_hover={{ bg: 'transparent', fontSize: '22px' }}
							/>
						</InputRightElement>
					</InputGroup>

					<Box width="300px" mt={4}>
						<Button
							type="submit"
							bg={ProjectConfig.ARCA_COLORS.LIGHT_GREY_02}
							_hover={{
								textDecoration: 'none',
								color: ProjectConfig.ARCA_COLORS.WHITE,
								background: ProjectConfig.ARCA_COLORS.GREEN_900,
								border: 'none'
							}}>
							{isLoading ? <ButtonSpinner /> : translate('general.login.access')}
						</Button>
					</Box>
					<Box mt={-2}>
						<Button
							background="none"
							position="relative"
							padding="0"
							height="auto"
							_hover={{
								background: 'transparent',
								border: 'none'
							}}>
							<Box
								as="span"
								position="relative"
								_before={{
									content: '""',
									position: 'absolute',
									width: '100%',
									height: '1px',
									bottom: '-3px',
									left: '50%',
									transform: 'translateX(-50%)',
									backgroundColor: 'currentColor',
									transition: 'all 0.3s ease-in-out'
								}}
								_hover={{
									_before: {
										width: '0%'
									}
								}}
								onClick={() => navigate('/forget-password')}>
								{translate('general.login.forgotPassword')}
							</Box>
						</Button>
					</Box>
				</form>
			</div>
			<GenericModal
				isOpen={isOpen}
				onClose={onClose}
				message={translate('general.login.errorMessage')}
				isError={true}
				closeModalButton={true}
			/>
		</div>
	);
};

export default LoginForm;
