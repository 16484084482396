import React, { useContext, useEffect, useState } from 'react';
import { Text, Grid, GridItem, SimpleGrid, Box, Card, CardHeader } from '@chakra-ui/react';
import { Bar, Doughnut } from 'react-chartjs-2';
import {
	Chart as ChartJS,
	ArcElement,
	Tooltip,
	Legend,
	CategoryScale,
	LineElement,
	LinearScale,
	PointElement,
	Title,
	ChartData,
	ChartOptions
} from 'chart.js';

import { ArcaContext } from '../../../context/ArcaContext.context';
import BenUserCard from '../../../components/ChakraUi/Cards/BenUserCard';
import { I18nContext } from '../../../context/translations/TranslationsContext.context';
import ProjectConfig from '../../../constants';
import LoadingViewSpinner from '../../../components/ChakraUi/Spinners/LoadingViewSpinner';
import getOnboardingStatus from '../../../api/companies/getOnboardingStatus';
import getPensionPlanPerformance, {
	PensionPlanPerformanceData
} from '../../../api/calculators/getPensionPlanPerformance';
import { formatArcaNumber } from '../../../utils/formatArcaNumbers';
import SectionTitle from '../../../components/Titles/SectionTitle';
import SelectCurrentYear from '../../../components/SelectCurrentYear';
import getAdminMonthlyContributionsByYear, {
	AdminMonthlyContributionsByYear
} from '../../../api/calculators/getAdminMonthlyContributionsByYear';
import ConfidenceBoosterLiteComponent from '../../../components/ConfidenceBoosterLiteComponent';

interface AdminInicioTabProps {
	userName: string;
	representsCompany?: boolean;
	representsWorkers?: boolean;
}

ChartJS.register(
	ArcElement,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
);

const AdminInicioTab: React.FC<AdminInicioTabProps> = ({
	userName,
	representsCompany,
	representsWorkers
}: AdminInicioTabProps) => {
	const {
		state: { translate }
	} = useContext(I18nContext);

	const representsCompanyText = representsCompany
		? translate('admin.home.companyRepresentative')
		: '';
	const representsWorkersText = representsWorkers
		? translate('admin.home.workersRepresentative')
		: '';

	const additionalText = representsCompany
		? `  ${representsCompanyText}`
		: representsWorkers
			? `  ${representsWorkersText}`
			: '';

	const [loading, setLoading] = useState<boolean>(true);
	const [loadingChart, setLoadingChart] = useState<boolean>(true);
	const [onboardingStatusData, setOnboardingStatusData] = useState<any>();
	const [pensionPlanPerformanceData, setPensionPlanPerformanceData] =
		useState<PensionPlanPerformanceData>();
	const [adminMonthlyContributionsByYear, setAdminMonthlyContributionsByYear] =
		useState<AdminMonthlyContributionsByYear>();
	const [error, setError] = useState<string | null>(null);

	const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear());

	const { token } = useContext(ArcaContext);

	useEffect(() => {
		const fetchOnboardingStatus = async () => {
			try {
				const response = await getOnboardingStatus(token);
				setOnboardingStatusData(response.data);
			} catch (err) {
				setError('Failed to fetch onboarding status');
			}
		};

		fetchOnboardingStatus();
	}, [token]);

	useEffect(() => {
		const fetchPensionPlanPerformance = async () => {
			try {
				const response = await getPensionPlanPerformance(token);
				setPensionPlanPerformanceData(response.data);
			} catch (err) {
				setError('Failed to fetch pension plan performance');
			}
		};

		const fetchData = async () => {
			setLoading(true);
			await Promise.all([fetchPensionPlanPerformance()]);
			setLoading(false);
		};

		fetchData();
	}, [token]);

	useEffect(() => {
		const fetchAdminMonthlyContributionsByYear = async () => {
			try {
				const response = await getAdminMonthlyContributionsByYear(token, selectedYear);
				setAdminMonthlyContributionsByYear(response.data);
			} catch (err) {
				setError('Failed to fetch admin monthly contributions by year');
			}
		};

		const fetchData = async () => {
			setLoadingChart(true);
			await Promise.all([fetchAdminMonthlyContributionsByYear()]);
			setLoadingChart(false);
		};

		fetchData();
	}, [token, selectedYear]);

	const onBoardingLabelCompleted = `${translate('admin.home.onBoardingLabelCompleted')}`;
	const onBoardingLabelPending = `${translate('admin.home.onBoardingLabelPending')}`;
	const onBoardingLabelRejected = `${translate('admin.home.onBoardingLabelRejected')}`;

	const onboardingStatusArray = [
		onboardingStatusData?.completed ?? 0,
		onboardingStatusData?.pendingRegistration ?? 0,
		onboardingStatusData?.benefitDiscarded ?? 0
	];

	const data: ChartData<'doughnut'> = {
		labels: [onBoardingLabelCompleted, onBoardingLabelPending, onBoardingLabelRejected],
		datasets: [
			{
				label: `${translate('admin.home.labelNumber')}`,
				data: onboardingStatusArray,
				backgroundColor: [ProjectConfig.ARCA_COLORS.GREEN_700, '#FFF68F', '#ff8566'],
				borderColor: [ProjectConfig.ARCA_COLORS.GREEN_700, '#FFF68F', '#ff8566'],
				borderWidth: 2,
				hoverOffset: 3
			}
		]
	};

	const options: ChartOptions<'doughnut'> = {
		cutout: '70%',
		responsive: true,
		maintainAspectRatio: false,
		plugins: {
			legend: {
				position: 'right',
				labels: {
					generateLabels: function (chart) {
						const dataset = chart.data.datasets[0];

						if (!dataset || !dataset.data) return [];

						const total = dataset.data.reduce((acc: number, value: any) => {
							if (typeof value === 'number') {
								return acc + value;
							}
							return acc;
						}, 0);

						const labels = chart.data.labels!.map((label, i) => {
							const value = dataset.data[i] as number;
							const percentage = total > 0 ? ((value / total) * 100).toFixed(1) + '%' : '0%';

							const backgroundColor = Array.isArray(dataset.backgroundColor)
								? dataset.backgroundColor[i]
								: undefined;
							const borderColor = Array.isArray(dataset.borderColor)
								? dataset.borderColor[i]
								: undefined;

							return {
								text: `${label}: ${value} (${percentage})`,
								fillStyle: backgroundColor as string,
								strokeStyle: borderColor as string,
								lineWidth: dataset.borderWidth as number,
								hidden: isNaN(value) || value === null,
								index: i
							};
						});
						labels.push({
							text: `${translate('admin.home.cardSumOfValues')} : ${total}`,
							fillStyle: 'transparent',
							strokeStyle: 'transparent',
							lineWidth: 0,
							hidden: false,
							index: labels.length
						});

						return labels;
					},
					font: {
						size: 14
					}
				}
			}
		},
		spacing: 0
	};

	const options2 = {
		responsive: true,
		plugins: {
			legend: {
				position: 'bottom' as const
			},
			title: {
				display: false,
				text: 'Chart.js Line Chart'
			}
		}
	};

	const evolutionLabelEmployerContributions = translate(
		'admin.home.evolutionLabelEmployerContributions'
	);
	const evolutionLabelEmployeesContributions = translate(
		'admin.home.evolutionLabelEmployeesContributions'
	);

	const {
		totalCompanyContributions,
		totalParticipantsContributions,
		savingsByContingencesReduction,
		corporateTaxDeduction,
		nextContribution,
		planPerformance,
		totalSavingsNow
	} = pensionPlanPerformanceData || {};

	const { aportacionesNetasEmpresa, aportacionesNetasEmpleados, totalMonths } =
		adminMonthlyContributionsByYear || {};

	const totalNextContribution =
		(nextContribution?.participantsAmount ?? 0) + (nextContribution?.promoterAmount ?? 0);

	const totalSavingsGenerated = totalSavingsNow ?? 0;

	const labels: string[] = Array.from({ length: 12 }, (_, i) =>
		translate(`admin.home.evolutionLabelMonth${i + 1}`)
	);

	const months = totalMonths ? totalMonths : 12;
	const trimmedLabels = labels.slice(0, months);

	const data2 = {
		labels: trimmedLabels,
		datasets: [
			{
				label: evolutionLabelEmployerContributions,
				data: aportacionesNetasEmpresa,
				borderColor: ProjectConfig.ARCA_COLORS.GREEN_600,
				backgroundColor: ProjectConfig.ARCA_COLORS.GREEN_600,
				borderRadius: 6,
				barThickness: 16
			},
			{
				label: evolutionLabelEmployeesContributions,
				data: aportacionesNetasEmpleados,
				borderColor: ProjectConfig.ARCA_COLORS.GREEN_400,
				backgroundColor: ProjectConfig.ARCA_COLORS.GREEN_400,
				borderRadius: 6,
				barThickness: 16
			}
		]
	};

	return (
		<div>
			{error && <Text color="red">{error}</Text>}
			{loading ? (
				<>
					<Box h="80dvh" display="flex" justifyContent="center" alignItems="center">
						<LoadingViewSpinner />
					</Box>
				</>
			) : (
				<Grid
					templateAreas={{
						base: `"hello" "dEmpr" "dPlan" "eTiem" "eBooster"`,
						sm: `"hello hello" "dEmpr dEmpr" "dPlan dPlan" "eTiem eTiem" "eBooster eBooster"`,
						md: `"hello hello hello hello" "dEmpr dEmpr dEmpr dEmpr" "dPlan dPlan eTiem eTiem" "eBooster eBooster eBooster eBooster"`,
						xl: `"hello hello hello hello" "dEmpr dEmpr dEmpr dEmpr" "dPlan dPlan eTiem eTiem" "eBooster eBooster eBooster eBooster"`
					}}
					gridTemplateRows={{
						base: 'repeat(4, auto)',
						sm: 'repeat(3, auto)',
						md: 'auto auto auto',
						xl: 'minmax(100, auto) minmax(100px, auto) 1fr'
					}}
					gridTemplateColumns={{
						base: '1fr',
						sm: '1fr 1fr',
						md: '1fr 1fr 1fr 1fr',
						xl: '1fr 1fr 1fr 1fr'
					}}
					h="auto"
					gap="10px"
					color="black">
					<GridItem
						area={'hello'}
						display="flex"
						justifyContent="space-between"
						alignItems="center">
						<Box>
							<Text fontSize="48px" fontWeight="bold">
								{translate('admin.home.hello')}
								<Text as="span" color={ProjectConfig.ARCA_COLORS.GREEN_500}>
									{userName}
								</Text>
							</Text>
							<Text
								fontSize="20px"
								fontWeight="600"
								color={ProjectConfig.ARCA_COLORS.PINK_400}
								m="-15px 0px 0px 2px">
								{additionalText}
							</Text>
						</Box>
					</GridItem>

					<GridItem area={'dEmpr'}>
						<SectionTitle text={translate('admin.home.companyData')} />

						<SimpleGrid spacing="8px" minChildWidth="200px" h="90%">
							<BenUserCard
								heading={translate('admin.home.cardTotalCompanyContributed')}
								cardBody={`${formatArcaNumber(totalCompanyContributions)}€`}
								infoIconVisible={true}
								infoIconText={translate('admin.home.cardTotalCompanyContributedIconText')}
							/>
							<BenUserCard
								heading={translate('admin.home.cardTotalParticipantsContributed')}
								cardBody={`${formatArcaNumber(totalParticipantsContributions)}€`}
								infoIconVisible={true}
								infoIconText={translate('admin.home.cardTotalParticipantsContributedIconText')}
							/>
							<BenUserCard
								heading={translate('admin.home.cardPlanPerformance')}
								cardBody={`${formatArcaNumber(totalSavingsGenerated)}€`}
								infoIconVisible={true}
								infoIconText={translate('admin.home.cardPlanPerformanceIconText')}
								isClickable={true}
								path="/dashboard/staff"
							/>
							<BenUserCard
								heading={translate('admin.home.cardNextContribution')}
								cardBody={
									<>
										<Text as="span" fontSize="24px">
											{`${formatArcaNumber(totalNextContribution)}€`}
										</Text>
										<br />
										{nextContribution?.nextContributionDate}
									</>
								}
								cardBodyTextSize={{ base: '24px', sm: '26px', md: '28px', xl: '26px' }}
								infoIconVisible={true}
								infoIconText={translate('admin.home.cardNextContributionIconText')}
								isClickable={true}
								path="/dashboard/contributions"
							/>
						</SimpleGrid>
					</GridItem>

					<GridItem area={'dPlan'}>
						<SectionTitle text={translate('admin.home.staffData')} />
						<Grid
							templateAreas={`"onbS onbS"
                        "vBen creR"`}
							gridTemplateRows={'390px 1fr'}
							gridTemplateColumns={'1fr 1.5fr'}
							gap="4"
							color="blackAlpha.700">
							<GridItem area={'onbS'}>
								<Card
									bg="gray.300"
									backgroundColor={ProjectConfig.ARCA_COLORS.WHITE}
									borderRadius="16px"
									pr="16px">
									<CardHeader>
										<Text
											fontSize="22px"
											p="0px 0px 0px 16px"
											fontFamily={ProjectConfig.FONTS.INTER}>
											{translate('admin.home.cardOnBoardingStatus')}
										</Text>
									</CardHeader>
									<Box width="100%" height="300px" pb="24px">
										<Doughnut options={options} data={data} />
									</Box>
								</Card>
							</GridItem>
						</Grid>
					</GridItem>

					<GridItem area={'eTiem'}>
						<SectionTitle text={translate('admin.home.monthlyContributions')} />
						{loadingChart ? (
							<Box h="380px" display="flex" justifyContent="center" alignItems="center">
								<LoadingViewSpinner height="100px" width="100px" />
							</Box>
						) : (
							<Box
								bg="gray.300"
								borderRadius="16px"
								h="380px"
								p="10px 15px 10px 15px"
								mb="20px"
								ml="auto"
								backgroundColor={ProjectConfig.ARCA_COLORS.WHITE}>
								<Box display="flex" justifyContent="flex-end" alignItems="center" p={2}>
									<SelectCurrentYear
										selectedYear={selectedYear}
										setSelectedYear={setSelectedYear}
									/>
								</Box>
								<Bar options={options2} data={data2} />
							</Box>
						)}
					</GridItem>

					<GridItem area={'eBooster'} bg={ProjectConfig.ARCA_COLORS.WHITE}>
						<ConfidenceBoosterLiteComponent />
					</GridItem>
				</Grid>
			)}
		</div>
	);
};

export default AdminInicioTab;
