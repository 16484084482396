import {
	Box,
	Card,
	CardBody,
	Grid,
	GridItem,
	ListItem,
	Skeleton,
	Table,
	Tbody,
	Td,
	Text,
	Tfoot,
	Th,
	Thead,
	Tr,
	UnorderedList,
	useDisclosure,
	Button,
	IconButton
} from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import BenUserCard from '../../../components/ChakraUi/Cards/BenUserCard';
import ProjectConfig from '../../../constants';
import {
	ContributionFromEnum,
	ContributionStatusEnum,
	ContributionTypeEnum
} from '../../../api/users/interfaces/ContributionData';

import { ArcaContext } from '../../../context/ArcaContext.context';
import { I18nContext } from '../../../context/translations/TranslationsContext.context';
import { GenericPaginationOutput } from '../../../api/generic-dtos/genericPaginationOutput';
import Pagination from '../../../components/ChakraUi/Pagination';
import LoadingViewSpinner from '../../../components/ChakraUi/Spinners/LoadingViewSpinner';
import ArcaPrimaryButton from '../../../components/Buttons/ArcaPrimaryButton';
import getCompanyInfoEppConfig from '../../../api/companies/getCompanyInfoEppConfig';
import getCurrentUser from '../../../api/users/getCurrentUser';

import EMPTY_COMPANY_INFO, { CompanyInfoI } from '../../../api/companies/interfaces/CompanyInfo';
import EMPTY_COMPANY_EPP_CONFIG, {
	CompanyEppConfigI,
	ContributionPeriodicityEnum,
	EmployerContributionTypeEnum
} from '../../../api/companies/interfaces/CompanyEppConfig';
import { formatArcaNumber } from '../../../utils/formatArcaNumbers';
import getCalculatorsUserAportations, {
	PaginatedMonthContributions,
	PaginationParams
} from '../../../api/calculators/getCalculatorsUserAportations';
import updatePeriodicalContribution from '../../../api/users/updatePeriodicalContribution';
import SectionTitle from '../../../components/Titles/SectionTitle';
import BenUserCardHidden from '../../../components/ChakraUi/Cards/BenUserCardHidden';
import getCompanyNextContributionInfo, {
	CompanyNextContributionInfo
} from '../../../api/companies/getCompanyNextContributionInfo';
import { GenericBackendResponse } from '../../../api/genericBackendResponse';
import EMPTY_CURRENT_USER, { CurrentUserI } from '../../../api/users/interfaces/Users ';
import EditParticipantContributionModal from '../../../components/ChakraUi/Modals/EditContributionModal';
import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';

interface SettingMyBenefitProps {
	isFreelance: boolean;
}

interface CompanyInfoAndEppConfigI {
	company: CompanyInfoI;
	companyEppConfig: CompanyEppConfigI;
}
const emptyCompanyInfoAndEppConfig = {
	company: EMPTY_COMPANY_INFO,
	companyEppConfig: EMPTY_COMPANY_EPP_CONFIG
};

const SettingMyBenefit: React.FC<SettingMyBenefitProps> = ({
	isFreelance
}: SettingMyBenefitProps) => {
	const {
		state: { translate }
	} = useContext(I18nContext);

	const { token, companyId } = useContext(ArcaContext);

	const [reload, setReload] = useState<boolean>(false);
	const [initialLoading, setInitialLoading] = useState<boolean>(true);
	const [isPageLoading, setIsPageLoading] = useState<boolean>(false);
	const [companyEppInfo, setCompanyEppInfo] = useState<CompanyInfoAndEppConfigI>(
		emptyCompanyInfoAndEppConfig
	);

	const [currentUser, setCurrentUser] = useState<CurrentUserI>(EMPTY_CURRENT_USER);

	const [userAportationsByType, setUserAportationsByType] =
		useState<GenericPaginationOutput<PaginatedMonthContributions>>();

	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);

	const [contributionType, setContributionType] = useState(ContributionTypeEnum.FIXED);
	const [contributionFixedAmount, setContributionFixedAmount] = useState<number | ''>();
	const [contributeAlwaysMax, setContributeAlwaysMax] = useState<boolean>(false);
	// const [contributionPunctualAmount, setContributionPunctualAmount] = useState<number | ''>(100);

	const [nextContributionInfo, setNextContributionInfo] = useState<CompanyNextContributionInfo>();

	const [expandedRows, setExpandedRows] = useState<{ [key: string]: boolean }>({});

	useEffect(() => {
		setInitialLoading(true);

		const getCompanyInfoEppConfigAsync = async () => {
			if (typeof companyId === 'string' && companyId.trim().length > 0) {
				try {
					const currentCompanyInfoEppConfig = await getCompanyInfoEppConfig(token, companyId);
					const currentCompanyInfoEppConfigData =
						currentCompanyInfoEppConfig.data as CompanyInfoAndEppConfigI;
					setCompanyEppInfo(currentCompanyInfoEppConfigData);
				} catch (error) {
					console.error('Error fetching company info:', error);
				}
			}
		};

		const fetchCompanyNextContributionInfo = async () => {
			const res: GenericBackendResponse<CompanyNextContributionInfo> =
				await getCompanyNextContributionInfo(token);
			setNextContributionInfo(res.data);
		};

		if (!isFreelance) {
			getCompanyInfoEppConfigAsync();
			fetchCompanyNextContributionInfo();
		}

		const getCurrentUserAsync = async () => {
			const currentUser = await getCurrentUser(token);
			const currentUserData = currentUser.data as CurrentUserI;
			setCurrentUser(currentUserData);
			setContributionFixedAmount(currentUserData?.userEppConfig?.data?.pensionContribution);
			setContributeAlwaysMax(currentUserData?.userEppConfig?.data?.contributeAlwaysMax);
		};
		getCurrentUserAsync();

		setTimeout(() => {
			setInitialLoading(false);
		}, 1000);
	}, [token, reload, companyId, isFreelance]);

	useEffect(() => {
		const limitSelected = 5;

		const fetchUserAportations = async () => {
			try {
				const params: PaginationParams = {
					page: currentPage,
					limit: limitSelected
				};
				const res: GenericPaginationOutput<PaginatedMonthContributions> =
					await getCalculatorsUserAportations(token, params);
				setUserAportationsByType(res);
				setTotalPages(res.totalPages);
			} catch (error) {
				console.error('Failed to fetch user contributions:', error);
			} finally {
				setIsPageLoading(false);
			}
		};

		fetchUserAportations();
	}, [currentPage, token]);

	const {
		isOpen: isOpenEditContribution,
		onOpen: onOpenEditContribution,
		onClose: onCloseEditContribution
	} = useDisclosure();

	const handleChangeFixedContribution = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		if (value === '') {
			setContributionFixedAmount('');
		} else {
			const numericValue = Math.max(0, Math.min(Number(value), 99999));
			if (!isNaN(numericValue)) {
				setContributionFixedAmount(numericValue);
			}
		}
	};

	const handleSaveChangesEditFixedContribution = async () => {
		await updatePeriodicalContribution(token, Number(contributionFixedAmount), contributeAlwaysMax);
		onCloseEditContribution();
		setReload(!reload);
	};

	//  (PENDIENTE IMPLEMENTAR)
	// 	const handleChangePunctualContribution = (e: React.ChangeEvent<HTMLInputElement>) => {
	// 	const value = e.target.value;
	// 	if (value === '') {
	// 		setContributionPunctualAmount('');
	// 	} else {
	// 		const numericValue = Math.min(Number(value), 99999);
	// 		if (!isNaN(numericValue)) {
	// 			setContributionPunctualAmount(numericValue);
	// 		}
	// 	}
	// };

	//  (PENDIENTE IMPLEMENTAR)
	// 	const handleSaveChangesPunctualContribution = async () => {
	// 	const response = await makePunctualContribution(
	// 		token,
	// 		companyId,
	// 		userId,
	// 		contributionPunctualAmount
	// 	);

	// 	onClosePunctualContribution();
	// 	setReload((prev) => !prev);
	// };

	// const punctualContributionError = contributionPunctualAmount === 0;

	const handlePageChange = (page: number) => {
		setIsPageLoading(true);
		setCurrentPage(page);
	};

	const PeriodsListItem = () => {
		const mappingPeriods: Record<ContributionPeriodicityEnum, string> = {
			[ContributionPeriodicityEnum.MONTHLY]: 'onboarding.employee.condition2.2'
		};

		const periods = companyEppInfo?.companyEppConfig?.contributionPeriodicity;
		const conditionTranslationKey = mappingPeriods[periods];

		return (
			<ListItem mx="20px">
				{`${translate('onboarding.employee.condition2.1')}`}
				<Text as="span" fontWeight="bold">
					{conditionTranslationKey && translate(conditionTranslationKey)}.
				</Text>
			</ListItem>
		);
	};

	const eppConditionsList = (
		<UnorderedList fontSize="16px">
			{!isFreelance && (
				<>
					<PeriodsListItem />
					<ListItem mx="20px">
						{`${companyEppInfo.company.name} ${translate('onboarding.employee.condition1.1')}`}
						<Text as="span" fontWeight="bold">
							{companyEppInfo.companyEppConfig.employerContributionType ===
							EmployerContributionTypeEnum.GROSS_SALARY_PERCENTAGE
								? translate('onboarding.employee.condition1.2')
								: translate('onboarding.employee.condition1.3')}
							{companyEppInfo?.companyEppConfig?.employerContributionAmount}
							{companyEppInfo?.companyEppConfig?.employerContributionType ===
							EmployerContributionTypeEnum.GROSS_SALARY_PERCENTAGE
								? translate('onboarding.employee.condition4.2')
								: translate('onboarding.employee.condition4.1')}
						</Text>
					</ListItem>
					<ListItem mx="20px">
						{`${translate('onboarding.employee.condition3.1')}`}
						<Text as="span" fontWeight="bold">
							{companyEppInfo?.companyEppConfig?.employeeContributionRequired === false
								? translate('onboarding.employee.condition3.2')
								: translate('onboarding.employee.condition3.3') +
									translate('onboarding.employee.condition3.4') +
									companyEppInfo?.companyEppConfig?.employeeContributionMinimum +
									translate('onboarding.employee.condition4.1')}
						</Text>
					</ListItem>
				</>
			)}
			{isFreelance && (
				<ListItem mx="20px">
					{`${translate('onboarding.employee.condition3.1.freelance')}`}
				</ListItem>
			)}
		</UnorderedList>
	);

	const userGrossSalary = currentUser?.user?.grossAnnualSalary || 0;

	const periodicityMapping: Record<ContributionPeriodicityEnum, number> = {
		[ContributionPeriodicityEnum.MONTHLY]: 12
	};
	const prefixMapping: Record<ContributionPeriodicityEnum, string> = {
		[ContributionPeriodicityEnum.MONTHLY]: 'user.myContributions.euroPerMonth'
	};

	const periodicity = companyEppInfo?.companyEppConfig.contributionPeriodicity;

	const employerContribution = +companyEppInfo?.companyEppConfig?.employerContributionAmount;
	const contributionPeriodicity = periodicityMapping[periodicity] || 0;

	const companyContribution =
		companyEppInfo.companyEppConfig.employerContributionType ===
		EmployerContributionTypeEnum.GROSS_SALARY_PERCENTAGE
			? (userGrossSalary * employerContribution) / contributionPeriodicity / 100
			: employerContribution;
	const contributionPrefix = periodicity ? translate(prefixMapping[periodicity]) : '';

	const rows =
		userAportationsByType?.items?.map((monthGroup) => {
			const month = monthGroup.month;
			const contributions = monthGroup.contributions;

			const companyOrParticipantContributions = contributions.filter(
				(contribution) =>
					contribution.typeContribution === ContributionFromEnum.COMPANY ||
					contribution.typeContribution === ContributionFromEnum.PARTICIPANT
			);

			const companyContribution = contributions
				.filter((contribution) => contribution.typeContribution === ContributionFromEnum.COMPANY)
				.reduce((sum, contribution) => sum + contribution.contributionAmount, 0);

			const participantContribution = contributions
				.filter(
					(contribution) => contribution.typeContribution === ContributionFromEnum.PARTICIPANT
				)
				.reduce((sum, contribution) => sum + contribution.contributionAmount, 0);

			const totalContribution = companyContribution + participantContribution;

			const contributionStatusEnum = contributions.every(
				(contribution) => contribution.statusContribution === ContributionStatusEnum.ERROR
			)
				? ContributionStatusEnum.ERROR
				: contributions.every(
							(contribution) => contribution.statusContribution === ContributionStatusEnum.CONFIRMED
					  )
					? ContributionStatusEnum.CONFIRMED
					: ContributionStatusEnum.PENDING;

			const contributionStatus =
				contributionStatusEnum === ContributionStatusEnum.ERROR
					? translate('user.myContributions.contributionsTableStatusERROR')
					: contributionStatusEnum === ContributionStatusEnum.CONFIRMED
						? translate('user.myContributions.contributionsTableStatusCONFIRMED')
						: translate('user.myContributions.contributionsTableStatusPENDING');

			return {
				month,
				companyContribution: companyContribution
					? `${formatArcaNumber(companyContribution)} €`
					: '0 €',
				participantContribution: participantContribution
					? `${formatArcaNumber(participantContribution)} €`
					: '0 €',
				totalContribution: `${formatArcaNumber(totalContribution)} €`,
				contributionStatus,
				companyOrParticipantContributions
			};
		}) || [];

	const participantIsContributing =
		currentUser?.userEppConfig.data.pensionContribution !== 0 ? true : false;

	const userNextContributionDate = nextContributionInfo?.nextContributionDate;

	const freelanceNextContributionDay = currentUser.userEppConfig.data.freelanceContributionDay;
	const formattedFreelanceNextContributionDay =
		freelanceNextContributionDay < 10
			? `0${freelanceNextContributionDay}`
			: freelanceNextContributionDay;

	const currentDate = new Date();
	const currentYear = currentDate.getFullYear();
	let currentMonth = currentDate.getMonth() + 1;
	const currentDay = currentDate.getDate();

	if (currentDay >= freelanceNextContributionDay) {
		currentMonth += 1;
	}

	let nextYear = currentYear;
	if (currentMonth > 12) {
		currentMonth = 1;
		nextYear += 1;
	}

	const formattedCurrentMonth = currentMonth < 10 ? `0${currentMonth}` : currentMonth;

	const freelanceNextContributionDate = `${nextYear}-${formattedCurrentMonth}-${formattedFreelanceNextContributionDay}`;

	const toggleRow = (month: string) => {
		setExpandedRows((prev) => ({
			...prev,
			[month]: !prev[month]
		}));
	};

	return (
		<div>
			{initialLoading ? (
				<Box h="80dvh" display="flex" justifyContent="center" alignItems="center">
					<LoadingViewSpinner />
				</Box>
			) : (
				<div>
					<Grid
						templateAreas={{
							base: `"slo00"
									"slo01"
									"slo03" 
									"slo02"
									
									"slo10"`,
							xl: `"slo00 slo03 slo02"
								"slo01 slo11 ."
								"slo10 slo10 slo10"`
						}}
						gridTemplateRows={{
							base: 'repeat(5,auto)',
							xl: 'repeat(2,auto) 1fr'
						}}
						gridTemplateColumns={{
							base: '1fr',
							xl: 'repeat(2, 1fr) minmax(250px, 530px)'
						}}
						h="100vh"
						gap={{ base: '32px', xl: '32px' }}
						color="blackAlpha.700">
						<GridItem area="slo00" display="flex">
							<Box textAlign="start">
								<SectionTitle
									text={
										isFreelance
											? translate('user.myContributions.contributions')
											: translate('user.myContributions.contributionType')
									}
								/>

								{isFreelance ? (
									<Box display="flex" justifyItems="center" justifyContent="center">
										<BenUserCard
											heading={` ${currentUser?.user.name} ${translate('user.myContributions.cardContributionFromParticipant')} `}
											cardBody={
												participantIsContributing ? (
													`${currentUser?.userEppConfig.data.pensionContribution} ${contributionPrefix}`
												) : (
													<div>
														<ArcaPrimaryButton onClick={onOpenEditContribution}>
															{translate('user.myContributions.cardParticipantButton')}
														</ArcaPrimaryButton>
													</div>
												)
											}
											infoIconVisible={false}
											infoIconText={translate('user.myContributions.cardAmountContributedIconText')}
										/>
									</Box>
								) : (
									<Box display="flex" justifyItems="center" justifyContent="center" gap="16px">
										<BenUserCard
											heading={` ${companyEppInfo?.company?.name} ${translate('user.myContributions.cardContributionFromEmployer')} `}
											cardBody={` ${formatArcaNumber(companyContribution)} ${contributionPrefix} `}
											infoIconVisible={false}
											infoIconText={translate('user.myContributions.cardAmountContributedIconText')}
											width="200px"
										/>
										<BenUserCard
											heading={` ${currentUser?.user.name} ${translate('user.myContributions.cardContributionFromParticipant')} `}
											cardBody={
												participantIsContributing ? (
													`${currentUser?.userEppConfig.data.pensionContribution} ${contributionPrefix}`
												) : (
													<div>
														<ArcaPrimaryButton onClick={onOpenEditContribution}>
															{translate('user.myContributions.cardParticipantButton')}
														</ArcaPrimaryButton>
													</div>
												)
											}
											infoIconVisible={false}
											infoIconText={translate('user.myContributions.cardAmountContributedIconText')}
										/>
									</Box>
								)}
							</Box>
						</GridItem>

						<GridItem
							area="slo03"
							display="flex"
							flexDirection="column"
							justifySelf={{ xl: 'center', base: 'left' }}>
							<SectionTitle text={translate('user.myContributions.datesTitle')} />
							<BenUserCard
								heading={translate('user.myContributions.nextContribution')}
								cardBody={!isFreelance ? userNextContributionDate : freelanceNextContributionDate} //TODO Traerme el valor de la fecha de la proxima aportacion para el usuario
								infoIconVisible={false}
								textSize="26px"
								infoIconText={translate('user.myContributions.cardAmountContributedIconText')}
								width="fit-content"
							/>
						</GridItem>

						{/* Card con las condiciones del plan de pensiones de empleo del usuario */}
						<GridItem area="slo02">
							<Box textAlign="start" width="fit-content" min-height="auto">
								<SectionTitle text={translate('user.myContributions.cardPensionPlanConditions')} />

								{/* Ajuste de ancho en la tarjeta de condiciones */}
								<Box display="flex" flexDirection="column" gap="16px" maxW="fit-content">
									<Box flex="1">
										<Card
											backgroundColor={ProjectConfig.ARCA_COLORS.WHITE}
											borderRadius="12px"
											minH="190px">
											<CardBody display="flex" alignItems="center" justifyContent="center">
												<Text mt="0px" mb="5px">
													{eppConditionsList}
												</Text>
											</CardBody>
										</Card>
									</Box>

									{/* Ajuste en la tarjeta de Cuenta Bancaria */}
									{isFreelance && (
										<Box flex="1" minW="200px">
											<BenUserCardHidden
												heading={
													currentUser?.user.bankAccount
														? translate('admin.contributions.bankAccount')
														: translate('admin.contributions.bankAccountConfigNeeded')
												}
												cardBody={`${currentUser?.user.bankAccount ?? ''}`}
												infoIconVisible={false}
												infoIconText={translate(
													'user.myContributions.cardAmountContributedIconText'
												)}
												width="100%"
												minW="200px"
											/>
										</Box>
									)}
								</Box>
							</Box>
						</GridItem>

						{/* Botón para abrir el Modal que permite editar el tipo de aportación */}

						{participantIsContributing ? (
							<GridItem area="slo01">
								<ArcaPrimaryButton onClick={onOpenEditContribution}>
									{translate('user.myContributions.editMyContribution')}
								</ArcaPrimaryButton>
							</GridItem>
						) : (
							<div></div>
						)}

						{/* Tabla de Aportaciones puntuales */}
						<GridItem area="slo10">
							<SectionTitle text={translate('user.myContributions.contributions')} />

							<Box
								p="15px 25px 0px 25px"
								backgroundColor={ProjectConfig.ARCA_COLORS.WHITE}
								borderRadius="12px">
								<Table minW="200px" width="100%">
									<Thead py="15px">
										<Tr>
											<Th
												color={ProjectConfig.ARCA_COLORS.GREEN_800}
												fontSize="14px"
												borderBottomWidth="2px"
												borderColor={ProjectConfig.ARCA_COLORS.GREEN_400}
												textAlign="center">
												{translate('user.myContributions.contributionsTableMonth')}
											</Th>
											{!isFreelance && (
												<Th
													color={ProjectConfig.ARCA_COLORS.GREEN_800}
													fontSize="14px"
													borderBottomWidth="2px"
													borderColor={ProjectConfig.ARCA_COLORS.GREEN_400}
													textAlign="center">
													{` ${companyEppInfo?.company?.name} ${translate('user.myContributions.contributionsTablePromoterContributions')} `}
												</Th>
											)}
											<Th
												color={ProjectConfig.ARCA_COLORS.GREEN_800}
												fontSize="14px"
												borderBottomWidth="2px"
												borderColor={ProjectConfig.ARCA_COLORS.GREEN_400}
												textAlign="center">
												{` ${currentUser?.user.name} ${translate('user.myContributions.contributionsTableParticipantContributions')} `}
											</Th>
											{!isFreelance && (
												<Th
													color={ProjectConfig.ARCA_COLORS.GREEN_800}
													fontSize="14px"
													borderBottomWidth="2px"
													borderColor={ProjectConfig.ARCA_COLORS.GREEN_400}
													textAlign="center">
													{translate('user.myContributions.contributionsTableTotalAmount')}
												</Th>
											)}
											<Th
												color={ProjectConfig.ARCA_COLORS.GREEN_800}
												fontSize="14px"
												borderBottomWidth="2px"
												borderColor={ProjectConfig.ARCA_COLORS.GREEN_400}
												textAlign="center">
												{translate('user.myContributions.contributionsTableStatusContribution')}
											</Th>
											<Th
												color={ProjectConfig.ARCA_COLORS.GREEN_800}
												fontSize="14px"
												borderBottomWidth="2px"
												borderColor={ProjectConfig.ARCA_COLORS.GREEN_400}
												textAlign="center">
												{translate('user.myContributions.liquidativeValue')}
											</Th>
											<Th
												color={ProjectConfig.ARCA_COLORS.GREEN_800}
												fontSize="14px"
												borderBottomWidth="2px"
												borderColor={ProjectConfig.ARCA_COLORS.GREEN_400}
												textAlign="center">
												{translate('user.myContributions.participations')}
											</Th>
										</Tr>
									</Thead>

									<Tbody>
										{isPageLoading
											? Array.from({ length: 5 }).map((_, index) => (
													<Tr key={index}>
														<Td py="10px" borderColor="gray.300" textAlign="center">
															<Skeleton height="20px" />
														</Td>
														{!isFreelance && (
															<Td py="10px" borderColor="gray.300" textAlign="center">
																<Skeleton height="20px" />
															</Td>
														)}
														<Td py="10px" borderColor="gray.300" textAlign="center">
															<Skeleton height="20px" />
														</Td>
														{!isFreelance && (
															<Td py="10px" borderColor="gray.300" textAlign="center">
																<Skeleton height="20px" />
															</Td>
														)}
														<Td py="10px" borderColor="gray.300" textAlign="center">
															<Skeleton height="20px" />
														</Td>
														<Td py="10px" borderColor="gray.300" textAlign="center">
															<Skeleton height="20px" />
														</Td>
														<Td py="10px" borderColor="gray.300" textAlign="center">
															<Skeleton height="20px" />
														</Td>
														<Td py="10px" borderColor="gray.300" textAlign="center">
															<Skeleton height="20px" />
														</Td>
														<Td py="10px" borderColor="gray.300" textAlign="center">
															<Skeleton height="20px" />
														</Td>
													</Tr>
												))
											: rows.map((row, index) => (
													<React.Fragment key={index}>
														<Tr>
															<Td py="10px" borderColor="gray.300" textAlign="center">
																{row.month}
																<IconButton
																	size="xs"
																	onClick={() => toggleRow(row.month)}
																	aria-label={expandedRows[row.month] ? 'Collapse' : 'Expand'}
																	icon={
																		expandedRows[row.month] ? (
																			<ChevronDownIcon />
																		) : (
																			<ChevronRightIcon />
																		)
																	}
																	mt={1}
																/>
															</Td>
															{!isFreelance && (
																<Td py="10px" borderColor="gray.300" textAlign="center">
																	{row.companyContribution}
																</Td>
															)}
															<Td py="10px" borderColor="gray.300" textAlign="center">
																{row.participantContribution}
															</Td>
															{!isFreelance && (
																<Td py="10px" borderColor="gray.300" textAlign="center">
																	{row.totalContribution}
																</Td>
															)}
															<Td py="10px" borderColor="gray.300" textAlign="center">
																{row.contributionStatus}
															</Td>
															<Td py="10px" borderColor="gray.300" textAlign="center">
																{'-'}
															</Td>
															<Td py="10px" borderColor="gray.300" textAlign="center">
																{'-'}
															</Td>
														</Tr>
														{/* Renderizar filas adicionales para cada aportación */}
														{expandedRows[row.month] &&
															row.companyOrParticipantContributions.map((contribution, idx) => (
																<Tr key={`contribution-${index}-${idx}`} bg="gray.100">
																	<Td py="10px" borderColor="gray.300" textAlign="center">
																		{contribution.dateContribution
																			? new Date(contribution.dateContribution).toLocaleDateString()
																			: 'N/A'}
																	</Td>
																	<Td py="10px" borderColor="gray.300" textAlign="center">
																		{contribution.typeContribution === ContributionFromEnum.COMPANY
																			? `${formatArcaNumber(contribution.contributionAmount)} €`
																			: '-'}
																	</Td>
																	<Td py="10px" borderColor="gray.300" textAlign="center">
																		{contribution.typeContribution ===
																		ContributionFromEnum.PARTICIPANT
																			? `${formatArcaNumber(contribution.contributionAmount)} €`
																			: '-'}
																	</Td>
																	<Td py="10px" borderColor="gray.300" textAlign="center">
																		{contribution.contributionAmount
																			? `${formatArcaNumber(contribution.contributionAmount)} €`
																			: '-'}
																	</Td>
																	<Td py="10px" borderColor="gray.300" textAlign="center">
																		{contribution.statusContribution
																			? translate(
																					`user.myContributions.contributionsTableStatus${contribution.statusContribution}`
																				)
																			: 'N/A'}
																	</Td>
																	<Td py="10px" borderColor="gray.300" textAlign="center">
																		{contribution.liquidationValue} €
																	</Td>
																	<Td py="10px" borderColor="gray.300" textAlign="center">
																		{contribution.sharesBought}
																	</Td>
																</Tr>
															))}
													</React.Fragment>
												))}
									</Tbody>

									<Tfoot>
										<Tr>
											<Td colSpan={9} textAlign="center" pb="5px">
												<Pagination
													currentPage={currentPage}
													totalPages={totalPages}
													onPageChange={handlePageChange}
												/>
											</Td>
										</Tr>
									</Tfoot>
								</Table>
							</Box>
						</GridItem>
					</Grid>
				</div>
			)}
			<EditParticipantContributionModal
				isOpen={isOpenEditContribution}
				onClose={onCloseEditContribution}
				isFreelance={isFreelance}
				contributionType={contributionType}
				setContributionType={setContributionType}
				contributionFixedAmount={contributionFixedAmount}
				handleChangeFixedContribution={handleChangeFixedContribution}
				handleSaveChangesEditFixedContribution={handleSaveChangesEditFixedContribution}
				translate={translate}
				companyEppConfig={companyEppInfo?.companyEppConfig}
				contributeAlwaysMax={contributeAlwaysMax}
				setContributeAlwaysMax={setContributeAlwaysMax}
			/>
		</div>
	);
};

export default SettingMyBenefit;
