import { GenericBackendResponse } from '../genericBackendResponse';

export interface AdminMonthlyContributionsByYear {
	totalMonths: number;
	aportacionesNetasEmpresa: number[];
	aportacionesNetasEmpleados: number[];
}

export type GetAdminMonthlyContributionsByYearResponse =
	GenericBackendResponse<AdminMonthlyContributionsByYear>;

const getAdminMonthlyContributionsByYear = async (
	token: string,
	year: number
): Promise<GetAdminMonthlyContributionsByYearResponse> => {
	const response = await fetch(
		`${process.env.REACT_APP_BASE_API}/calculators/admin-monthly-contributions-by-year`,
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`
			},
			body: JSON.stringify({ year })
		}
	);

	if (!response.ok) {
		throw new Error('Network response was not ok');
	}

	const result: GetAdminMonthlyContributionsByYearResponse = await response.json();
	return result;
};

export default getAdminMonthlyContributionsByYear;
