import { GenericBackendResponse } from '../genericBackendResponse';

export interface CurrentConfig {
	contributionPeriodicity: string;
	participantContributionPerPeriod: number;
}

export interface RecommendedConfig {
	contributionPeriodicity: string;
	participantContributionPerPeriod: number;
}

export interface MaxAportationsInfoOutput {
	maxUserContributionThisYear: number;
	companyThisYearCompanyCommitted: number;
	maxUserContributionEndOfNextPeriod: number;
	currentConfig: CurrentConfig;
	recommendedConfig: RecommendedConfig;
	needToProvideGrossSalary: boolean;
}

export interface CurrentConfigFreelance {
	contributionPeriodicity: string;
	contributionPerPeriod: number;
}

export interface RecommendedConfigFreelance {
	contributionPeriodicity: string;
	contributionPerPeriod: number;
}

export interface MaxAportationsInfoFreelanceOutput {
	pendingYearContributions: number;
	currentConfig: CurrentConfigFreelance;
	recommendedConfig: RecommendedConfigFreelance;
}

const calculateMaxAportationsInfo = async (
	token: string
): Promise<
	GenericBackendResponse<MaxAportationsInfoOutput | MaxAportationsInfoFreelanceOutput>
> => {
	const response = await fetch(
		`${process.env.REACT_APP_BASE_API}/calculators/calculate-max-aportations-info`,
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`
			}
		}
	);

	return response.json();
};

export default calculateMaxAportationsInfo;
