import { Box, Text } from '@chakra-ui/react';

const ConfidenceBoosterComponent = ({}) => {
	return (
		<Box display="flex" flexDirection="column" alignItems="center">
			<Box display="flex" justifyContent="center" mt={4} mb={4}>
				<img
					src="/imgs/cecabank.png"
					alt="Cecabank"
					style={{ marginRight: '20px', maxWidth: '150px', height: 'auto', width: 'auto' }}
				/>
				<img
					src="/imgs/caser-helvetia.png"
					alt="Caser Helvetia"
					style={{ maxWidth: '150px', height: 'auto', width: 'auto' }}
				/>
			</Box>
			<Text fontSize="16px" maxWidth="600px">
				Tus planes de pensiones están gestionados por Caser Pensiones, asesorados por Indexa y
				depositados en Cecabank, dentro del marco del contrato de gestión de carteras que tienes con
				Indexa.
			</Text>
		</Box>
	);
};

export default ConfidenceBoosterComponent;
