import { GenericPaginationOutput } from '../generic-dtos/genericPaginationOutput';
import { ContributionFromEnum } from '../users/interfaces/ContributionData';

export interface PaginationParams {
	page: number;
	limit: number;
	contributionType?: ContributionFromEnum;
	year?: number;
}

export interface ContributionData {
	id: string;
	createdAt: string;
	updatedAt: string;
	companyId: string;
	userId: string;
	contributionAmount: number;
	typeContribution: ContributionFromEnum;
	dateContribution: string;
	statusContribution: string;
	liquidationValue: number;
	sharesBought: number;
	totalShares: number;
}

export interface PaginatedMonthContributions {
	month: string;
	performanceEuros: number;
	performancePercentage: number;
	contributions: ContributionData[];
}

const getCalculatorsUserAportations = async (
	token: string,
	params: PaginationParams
): Promise<GenericPaginationOutput<PaginatedMonthContributions>> => {
	const response = await fetch(
		`${process.env.REACT_APP_BASE_API}/calculators/user-aportations-by-type`,
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`
			},
			body: JSON.stringify(params)
		}
	);

	if (!response.ok) {
		throw new Error('Network response was not ok');
	}

	const result = await response.json();
	return result.data; // Accede al campo data en la respuesta
};

export default getCalculatorsUserAportations;
