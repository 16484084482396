import { ContributionPeriodicityEnum } from '../../companies/interfaces/CompanyEppConfig';
import { ContributionTypeEnum } from './ContributionData';

export enum UserGenderEnum {
	MEN = 'V',
	WOMEN = 'M'
}
// FIXME START Se podría dejar un solo interface pero habría que ir archivo a archivo cambiandolo todo.
export interface UserI {
	id: number;
	created_at: string;
	updated_at: string;
	email: string;
	password: string;
	name: string;
	phone: string;
	roles: any;
	firstSurname: string;
	lastSurname: string;
	gender: UserGenderEnum;
	language: string;
	nationality: string;
	country: string;
	nif: string;
	birthdate: string;
	company_name: string;
	company_cif: string;
	street: string;
	zip: string;
	province: string;
	population: string;
	bank_account: string;
	last_login: string;
	verification_link: string;
	encrypted_data: string;
	status: string;
	company_id: string;
	representsCompany: boolean;
	representsParticipant: boolean;
	completedOnboarding: boolean;
	grossAnnualSalary: number;
}
//FIXME END

export interface UserEppConfigData {
	pensionContribution: number;
	contributionPeriodicity: ContributionPeriodicityEnum;
	employeeContributionType: ContributionTypeEnum;
}

export interface FreelanceEppConfigData {
	fondo: string;
	plan: string;
	subplan: string;
	pensionContribution: number;
	contributionPeriodicity: ContributionPeriodicityEnum;
	freelanceContributionType: ContributionTypeEnum;
	freelanceContributionDay: number;
	freelanceContributionPeriodicity: ContributionPeriodicityEnum;
}

export interface CurrentUserI {
	user: {
		id: number;
		created_at: string;
		updated_at: string;
		isFreelance: boolean;
		email: string;
		password: string;
		name: string;
		phone: string;
		roles: any;
		firstSurname: string;
		lastSurname: string;
		gender: UserGenderEnum;
		language: string;
		nationality: string;
		country: string;
		nif: string;
		birthdate: string;
		company_name: string;
		company_cif: string;
		street: string;
		zip: string;
		province: string;
		population: string;
		bankAccount: string;
		last_login: string;
		verification_link: string;
		encrypted_data: string;
		status: string;
		company_id: string;
		representsCompany: boolean;
		representsParticipant: boolean;
		completedOnboarding: boolean;
		grossAnnualSalary: number;
		lastMovementsUpdate: string;
	};
	userEppConfig: {
		id: string;
		created_at: string;
		updated_at: string;
		userId: string;
		status: string;
		data: {
			contributeAlwaysMax: boolean;
			fondo: string;
			plan: string;
			subplan: string;
			pensionContribution: number;
			contributionPeriodicity: string;
			freelanceContributionType: ContributionTypeEnum;
			freelanceContributionDay: number;
			freelanceContributionPeriodicity: ContributionPeriodicityEnum;
			employeeContributionType: ContributionTypeEnum;
		};
		lastContributionDate: string;
		linkRegulation: string;
	};
}

const EMPTY_CURRENT_USER = {
	user: {
		id: 0,
		created_at: '',
		updated_at: '',
		isFreelance: false,
		email: '',
		password: '',
		name: '',
		phone: '',
		roles: [],
		firstSurname: '',
		lastSurname: '',
		gender: UserGenderEnum.MEN,
		language: '',
		nationality: '',
		country: '',
		nif: '',
		birthdate: '',
		company_name: '',
		company_cif: '',
		street: '',
		zip: '',
		province: '',
		population: '',
		bankAccount: '',
		last_login: '',
		verification_link: '',
		encrypted_data: '',
		status: '',
		company_id: '',
		representsCompany: false,
		representsParticipant: false,
		completedOnboarding: false,
		grossAnnualSalary: 0,
		lastMovementsUpdate: ''
	},
	userEppConfig: {
		id: '',
		created_at: '',
		updated_at: '',
		userId: '',
		status: '',
		data: {
			contributeAlwaysMax: false,
			fondo: '',
			plan: '',
			subplan: '',
			pensionContribution: 0,
			contributionPeriodicity: '',
			freelanceContributionType: ContributionTypeEnum.FIXED,
			freelanceContributionDay: 0,
			freelanceContributionPeriodicity: ContributionPeriodicityEnum.MONTHLY,
			employeeContributionType: ContributionTypeEnum.FIXED
		},
		lastContributionDate: '',
		linkRegulation: ''
	}
};

export default EMPTY_CURRENT_USER;
