import { FiAlertCircle } from 'react-icons/fi';
import { Tooltip, Box, Text, Link } from '@chakra-ui/react';
import ProjectConfig from '../../constants';

/** #BOOSTME Pendiente de ver si se pudiera poner un blur alrededor del Tooltip para destacarlo aún más */

interface infoIconClickProps {
	text: string;
	isVisible: boolean;
	maxW?: string;
	showLink?: string;
}

const InfoIconClick: React.FC<infoIconClickProps> = ({
	text,
	isVisible,
	maxW = '200px',
	showLink = ''
}) => {
	if (!isVisible) {
		return null;
	}

	const handleClick = () => {
		if (showLink) {
			window.open(showLink, '_blank');
		}
	};

	return (
		<Tooltip
			label={
				<Box>
					<Text color="white">{text}</Text>
					{showLink !== '' && (
						<Link href={showLink} isExternal textDecoration="underline">
							{showLink}
						</Link>
					)}
				</Box>
			}
			openDelay={300}
			bg="gray.800"
			color="white"
			borderRadius="md"
			p="15px"
			maxW={maxW}
			placement="right-start"
			fontSize="15px"
			shadow="dark-lg"
			colorScheme={ProjectConfig.ARCA_COLORS.PINK_500}
			closeOnPointerDown={false}
			closeOnMouseDown={false}
			closeOnClick={false}>
			<Box as="span" color={ProjectConfig.ARCA_COLORS.PINK_500} onClick={handleClick}>
				<FiAlertCircle />
			</Box>
		</Tooltip>
	);
};

export default InfoIconClick;
