const updatePeriodicalContribution = async (
	token: string,
	pensionContribution: number,
	contributeAlwaysMax: boolean
) => {
	const response = await fetch(
		`${process.env.REACT_APP_BASE_API}/users/update-periodical-contribution`,
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`
			},
			body: JSON.stringify({ pensionContribution, contributeAlwaysMax })
		}
	);

	return response.json();
};

export default updatePeriodicalContribution;
