const forgetPassword = async (email: string) => {
	const response = await fetch(`${process.env.REACT_APP_BASE_API}/users/forget-password`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({ email })
	});

	return response.json();
};

export default forgetPassword;
