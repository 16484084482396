import { Spinner } from '@chakra-ui/react';
import ProjectConfig from '../../../constants';

interface LoadingViewSpinnerProps {
	height?: string;
	width?: string;
}

const LoadingViewSpinner: React.FC<LoadingViewSpinnerProps> = ({
	height = '300px',
	width = '300px'
}) => {
	return (
		<Spinner
			thickness="50px"
			speed="1.5s"
			emptyColor={ProjectConfig.ARCA_COLORS.GREEN_400}
			color={ProjectConfig.ARCA_COLORS.GREEN_800}
			height={height}
			width={width}
		/>
	);
};

export default LoadingViewSpinner;
