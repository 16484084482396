export enum ContributionFromEnum {
	COMPANY = 'COMPANY',
	PARTICIPANT = 'PARTICIPANT',
	TRANSFER_INTERNAL_OUTPUT = 'TRANSFER_INTERNAL_OUTPUT',
	TRANSFER_INTERNAL_INPUT = 'TRANSFER_INTERNAL_INPUT',
	CANCELLMENT_INTERNAL_OUTPUT = 'CANCELLMENT_INTERNAL_OUTPUT',
	CANCELLMENT_INTERNAL_INPUT = 'CANCELLMENT_INTERNAL_INPUT',
	CANCELLMENT_PROMOTER_SAVINGS = 'CANCELLMENT_PROMOTER_SAVINGS',
	CANCELLMENT_PARTICIPANT_SAVINGS = 'CANCELLMENT_PARTICIPANT_SAVINGS',
	CANCELLMENT_PARTICIPANT_SAVINGS_EXTRAORDINARY = 'CANCELLMENT_PARTICIPANT_SAVINGS_EXTRAORDINARY'
}

export enum ContributionStatusEnum {
	PENDING = 'PENDING',
	CONFIRMED = 'CONFIRMED',
	ERROR = 'ERROR'
}

export enum ContributionTypeEnum {
	PERCENTAGE = 'PERCENTAGE',
	FIXED = 'FIXED'
}

export interface SingleUserContributionData {
	id: string;
	createdAt: string;
	updatedAt: string;
	companyId: string;
	userId: string;
	contributionAmount: number;
	typeContribution: ContributionFromEnum;
	dateContribution: string;
	statusContribution: ContributionStatusEnum;
	liquidationValue: number;
	sharesBought: number;
	totalShares: number;
}
export interface LineData {
	labels: string[];
	datasets: Dataset[];
}

export interface BarData {
	labels: string[];
	datasets: Dataset[];
}

interface Dataset {
	label: string;
	data: number[];
	backgroundColor: string;
	hoverBackgroundColor?: string;
	borderColor?: string;
	borderWidth?: number;
	borderRadius?: number;
	barThickness?: number;
}
