const findParticipantsContributionData = async (
	token: string,
	page: number,
	limit: number,
	year: number
) => {
	const response = await fetch(
		`${process.env.REACT_APP_BASE_API}/calculators/participants-contributions`,
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`
			},
			body: JSON.stringify({ page, limit, year })
		}
	);

	return response.json();
};

export default findParticipantsContributionData;
